
@font-face {
    font-family: "robotoBlack";
    src: local("robotoBlack"),
    url("../fonts/roboto/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: "robotoBlackItalic";
    src: local("robotoBlackItalic"),
    url("../fonts/roboto/Roboto-BlackItalic.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: "robotoBold";
    src: local("robotoBold"),
    url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "robotoBoldItalic";
    src: local("robotoBoldItalic"),
    url("../fonts/roboto/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "robotoItalic";
    src: local("robotoBoldItalic"),
    url("../fonts/roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "robotoLight";
    src: local("robotoLight"),
    url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "robotoLightItalic";
    src: local("robotoLightItalic"),
    url("../fonts/roboto/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "robotoMedium";
    src: local("robotoMedium"),
    url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "robotoMediumItalic";
    src: local("robotoMediumItalic"),
    url("../fonts/roboto/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "robotoRegular";
    src: local("robotoRegular"),
    url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "robotoThin";
    src: local("robotoThin"),
    url("../fonts/roboto/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "robotoThinItalic";
    src: local("robotoThinItalic"),
    url("../fonts/roboto/Roboto-ThinItalic.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "train-one";
    src: local("train-one"),
    url("../fonts/train-one/TrainOne-Regular.ttf") format("truetype");
    font-weight: 400;
}